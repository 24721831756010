import { useDispatch } from 'react-redux';
import { Row, Col, Card, Tooltip, Divider } from 'antd';
import { shallowEqual } from 'react-redux';

import DeepMdOdGlcFeatures from './deepmd-od-glc-features';
import DeepMdOdBaselineTesting from './deepmd-od-baseline-testing';
import DeepMdOdMedicalOpinion from './deepmd-od-medical-opinion';
import DeepMdOdCounsellingRecommendations from './deepmd-od-counselling-recommendations';

import HelpDeskButton from '../../retina/button-help-desk';
import { convertTimeToLongFormatLabel } from '../../../helpers/utilities';
import { InfoCircleOutlined } from '@ant-design/icons';
import DeepMDLogo from '../../../../static/images/Care1AI.png';
import '../../../../static/css/components/deepmd-od-band.scss';
import { localizedText } from '../../../localizedText';
import { setHelpDeskField } from '../../../reducers/help-desk-slice';
import { useCare1AppSelector } from '../../../apps/care1-hooks';
import { lengthEqual } from '../../../reducers/patient-exam-slice';

const DeepMDBandOD = () => {
    const { DEEPMD_HELP_TRAIN, DEEPMD_INACCURACY_TOOLTIP, DEEPMD_SUBHEADING_TEXT } = localizedText;

    const patientFirstname  = useCare1AppSelector(store => store.patientDetails.details.first_name);
    const patientLastname  = useCare1AppSelector(store => store.patientDetails.details.last_name);
    const examDate  = useCare1AppSelector(store => store.examData.exam_date);
    const examId  = useCare1AppSelector(store => store.examData.id);
    const isGlc = useCare1AppSelector(store => store.examData.rr_glc);
    const isGlcSuspect = useCare1AppSelector(store => store.examData.rr_glc_suspect);

    const suspFeatures = useCare1AppSelector(store => store.examData.susp_features, shallowEqual);
    const reasFeatures = useCare1AppSelector(store => store.examData.reas_features, shallowEqual);

    const examsWithLeftVF = useCare1AppSelector(store => store.examData.exams_with_left_vf, lengthEqual);
    const examsWithRightVF = useCare1AppSelector(store => store.examData.exams_with_right_vf, lengthEqual);
    const examsWithLeftRNFL = useCare1AppSelector(store => store.examData.exams_with_left_oct_rnfl, lengthEqual);
    const examsWithRightRNFL = useCare1AppSelector(store => store.examData.exams_with_right_oct_rnfl, lengthEqual);
    const examsWithIOPLeftIOP = useCare1AppSelector(store => store.examData.graph_iop?.os_iop_points ?? [], lengthEqual);
    const examsWithIOPRightIOP = useCare1AppSelector(store => store.examData.graph_iop?.od_iop_points ?? [], lengthEqual);

    const rightIopComfortZone = useCare1AppSelector(store => store.txalgo3.od_iop_aim);
    const worstRecClass = useCare1AppSelector(store => store.txalgo3.class_aim);
    const shortestRecFu = useCare1AppSelector(store => store.txalgo3.followup_aim);
    const leftIopComfortZone = useCare1AppSelector(store => store.txalgo3.os_iop_aim);
    const fuPeriods = useCare1AppSelector(store => store.options.fuPeriods);

    const finalDeepMDRecommendations = useCare1AppSelector(store => store.txalgo3.final_deepmd_recommendations);
    const allLeftComparisons = useCare1AppSelector(store => store.txalgo3.left_comparisons);
    const allRightComparisons = useCare1AppSelector(store => store.txalgo3.right_comparisons);

    const dispatch = useDispatch();

    const afterOpenEvent = () => {
        const value = 'Reporting DeepMD Possible Outlier\n\n' +
            `Patient Name: ${patientFirstname} ${patientLastname}\n` +
            `Visit Date: ${convertTimeToLongFormatLabel(examDate)}\n` +
            `Exam ID: ${examId}\n\n` +
            `Please proved details regarding possible inaccuracy:`;

            dispatch(setHelpDeskField({field: 'helpRequest', value: value}));
            dispatch(setHelpDeskField({field: 'modalSource', value: 'omdr_counselling_recommendations'}));
    }

    const deepMdTooltipInfo = () => {
        return (
            <div className='deepmd-info'>
                <p>
                    {`The Care1 Glaucoma Band analyzes all information uploaded to the patient’s global chart on Care1, and outputs a summary of the patient’s glaucoma status.`}
                </p>
                <p>
                    {`This includes a list of suspicious and reassuring features of glaucoma, as well as recommendations for target IOP, follow-up, and counseling topics. Glaucoma AI Summary is experimental and in beta testing.`}
                </p>
            </div>
        )
    }

    return (
        <Card className='od-deepmd-band' bordered={false}>
            <Row className="deepmd-heading">
                <Col span={2}>
                    <img src={DeepMDLogo} className='deepmd-logo' alt='Care1 AI Logo' height={25} width={100} />
                </Col>
                <Col span={16}>
                    <Row className='subheading'>
                        <div className='deepmd-subheading'>{DEEPMD_SUBHEADING_TEXT}</div>
                        <Tooltip
                            placement='right'
                            overlayClassName='od-deepmd-info-tooltip'
                            title={deepMdTooltipInfo()}
                        >
                            <InfoCircleOutlined />
                        </Tooltip>
                    </Row>
                </Col>
                <Col span={6}>
                    <Row className='report-ai-inaccuracy-button'>
                        <HelpDeskButton text={DEEPMD_HELP_TRAIN} afterOpenEvent={afterOpenEvent} />
                        <Tooltip
                            placement='topLeft'
                            title={DEEPMD_INACCURACY_TOOLTIP}
                        >
                            <InfoCircleOutlined />
                        </Tooltip>
                    </Row>
                </Col>
            </Row>
            <Row className='full-band'>
                <Col>
                    <DeepMdOdGlcFeatures
                        suspFeatures={suspFeatures}
                        reasFeatures={reasFeatures}
                    />
                </Col>
                <Divider className={'divider'} type="vertical" />
                { (isGlc || isGlcSuspect) &&
                <>
                    <Col >
                        <DeepMdOdBaselineTesting 
                            examsWithLeftVF={examsWithLeftVF}
                            examsWithRightVF={examsWithRightVF}
                            examsWithLeftRNFL={examsWithLeftRNFL}
                            examsWithRightRNFL={examsWithRightRNFL}
                            examsWithIOPLeftIOP={examsWithIOPLeftIOP}
                            examsWithIOPRightIOP={examsWithIOPRightIOP}
                        />
                    </Col>
                    <Divider className={'divider'} type="vertical" />
                    <Col>
                        <DeepMdOdMedicalOpinion 
                            rightIopComfortZone={rightIopComfortZone}
                            worstRecClass={worstRecClass}
                            shortestRecFu={shortestRecFu}
                            leftIopComfortZone={leftIopComfortZone}
                            fuPeriods={fuPeriods}
                        />
                    </Col>
                    <Divider className={'divider'} type="vertical" />
                </>
                }
                
                <Col>
                    <DeepMdOdCounsellingRecommendations 
                        finalDeepMDRecommendations={finalDeepMDRecommendations}
                        allLeftComparisons={allLeftComparisons}
                        allRightComparisons={allRightComparisons}
                    />
                </Col>
            </Row>

        </Card>
    )
}

export default DeepMDBandOD
