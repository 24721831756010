import moment from 'moment-timezone';
import Cookies from 'universal-cookie';
import * as Constants from '../constants';
import React from 'react';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const developmentMode = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export function getCsrfToken() {
    return new Cookies().get('care1csrftoken');
}

export const padStringStart = (itemToPad: string, padMask: string) => {
    const content = itemToPad.toString();
    return content ? (padMask + content).slice(-padMask.length) : '';
};

export const showPlusSign = (value: string | number | undefined | null, info: {
    userTyping: boolean;
    input: string;
    }): string => {
    const input = info && info.input ? info.input: value;
    if (input === undefined || input === ''){
        return '';
    }

    // Regex: optional plus/negative first character or digit, 2nd char is optional digit,
    // 3rd is optional decimal, 4th and 5th are optional digits.
    
    const regex = /^([+-]?\d?\d?(\.\d{0,2})?)$/;
    const isMatch = regex.test(String(input));

    let formattedValue = input?.toString();
    
    if (!info.userTyping && input?.toString().length !== 5) {
        formattedValue = Number(input).toFixed(2).toString();
    }

    if (isMatch && formattedValue && formattedValue[0] !== '-' && formattedValue[0] !== '+') {
        return '+' + formattedValue;
    } else {
      return String(formattedValue);
    }
}

// Convert the given local date time zone to PST time zone.
export function convertLocalToPSTMoment(localDate: string) {
    if (localDate) {
        return moment(localDate).tz("US/Pacific").format(Constants.MOMENT_PST_FOR_BACKEND_REQUEST_FORMAT);
    }
    return '';
}

export function convertLocalToPST(localDate: string) {
    if (localDate) {
        dayjs.tz.setDefault("America/Vancouver");
        return dayjs(localDate).tz().format(Constants.MOMENT_PST_FOR_BACKEND_REQUEST_FORMAT);
    }
    return '';
}

// Convert the given date in string format in UTC time zone to the local time zone.
export function convertUtcToLocalMoment(utcDate: string) {
    if (utcDate) {
        return moment.utc(utcDate).local().format();
    }
    return '';
}
export function convertUtcToLocal(utcDate: string) {
    if (utcDate) {
        return dayjs(utcDate).format();
    }
    return '';
}

// // Convert the given string containing ISO format date time into a user-readable string with long month name.
export function convertTimeToLongFormatLabelMoment(timeValue: string) {
    if (timeValue) {
        const isValidFormat1 = moment(timeValue, 'MMMM DD, YYYY', true).isValid();
        const isValidFormat2 = moment(timeValue, moment.ISO_8601, true).isValid();

        if (isValidFormat1) {
            return moment(timeValue, 'MMMM DD, YYYY').format(Constants.FRONTEND_LONG_DATE_FORMAT);
        }
        else if (isValidFormat2) {
            return moment(timeValue, moment.ISO_8601).format(Constants.FRONTEND_LONG_DATE_FORMAT);
        }
        else {
            return moment(timeValue).format(Constants.FRONTEND_LONG_DATE_FORMAT);
        }
    }
    return '';
}

// Convert the given string containing ISO format date time into a user-readable string with long month name.
export function convertTimeToLongFormatLabel(timeValue: string) {
    if (timeValue) {
        return dayjs(timeValue).format(Constants.FRONTEND_LONG_DATE_FORMAT);
    }
    return '';
}

// Convert the given string containing ISO format date time into a user-readable string with short month name.
export function convertTimeToShortFormatLabelMoment(timeValue: string) {
    if (timeValue) {
        const reg = /([a-zA-Z]+) (\d+), (\d+)/;
        if (timeValue && timeValue.match(reg)) {
            const res = timeValue.match(reg);
            const monthMap: Record<string, string> = {
                'January': '01',
                'Jan': '01',
                'February': '02',
                'Feb': '02',
                'March': '03',
                'Mar': '03',
                'April': '04',
                'Apr': '04',
                'May': '05',
                'June': '06',
                'Jun': '06',
                'July': '07',
                'Jul': '07',
                'August': '08',
                'Aug': '08',
                'September': '09',
                'Sep': '09',
                'October': '10',
                'Oct': '10',
                'November': '11',
                'Nov': '11',
                'December': '12',
                'Dec': '12',
            };
            const fixTimeValue = `${res![3]}-${monthMap[res![1]]}-${res![2].toString().padStart(2, '0')}T12:00:00-08:00`;
            return moment(fixTimeValue).format(Constants.FRONTEND_SHORT_DATE_FORMAT);
        }
        else {
            return moment(timeValue).format(Constants.FRONTEND_SHORT_DATE_FORMAT);
        }
    }
    return '';
}

// Convert the given string containing ISO format date time into a user-readable string with short month name.
export function convertTimeToShortFormatLabel(timeValue: string) {
    if (timeValue) {
        return dayjs(timeValue).format(Constants.FRONTEND_SHORT_DATE_FORMAT);
    }
    return '';
}

// Convert a Python UNIX timestamp to "M dd, yyyy"
export function convertUNIXTimeToShortFormatLabel(timeValue: number){
    if(timeValue){
        const date = new Date(timeValue*1000);
        return date.toLocaleDateString("en-US", {month: 'short', day: 'numeric', year: 'numeric'});
    }
}

// Only allow decimal point and number key entry.
// Executing preventDefault will prevent the key from being entered and displaying.
// Call this function from the input onKeyPress event.
export function allowPosNumberAndDecimalOnlyKeys(onKeyPressEvent: React.KeyboardEvent<HTMLInputElement>) {
    if(isNaN(Number(onKeyPressEvent.key)) && onKeyPressEvent.key !== '.'){
        onKeyPressEvent.preventDefault()
    }
}

// Only allow decimal point and positive or negative number key entry.
// Executing preventDefault will prevent the key from being entered and displaying.
// Call this function from the input onKeyPress event.
export function allowPosAndNegNumberAndDecimalOnlyKeys(onKeyPressEvent: React.KeyboardEvent<HTMLInputElement>) {
    if(isNaN(Number(onKeyPressEvent.key)) && onKeyPressEvent.key !== '.' && onKeyPressEvent.key !== '-'){
        onKeyPressEvent.preventDefault()
    }
}

/// Only allow positive number key entry.
// Executing preventDefault will prevent the key from being entered and displaying.
// Call this function from the input onKeyPress event.
export function allowPosNumberOnlyKeys(onKeyPressEvent: React.KeyboardEvent<HTMLInputElement>) {
    if(isNaN(Number(onKeyPressEvent.key))){
        onKeyPressEvent.preventDefault()
    }
}

// Return a number to two decimal places without rounding up
export function twoDecimalsNotRounded(number: number){
    const twoDecimalRegExp = new RegExp('^\\d+(?:.\\d{0,2})?');

    const twoDecimalStringMatch = number.toString().match(twoDecimalRegExp);
    const twoDecimalString = twoDecimalStringMatch !== null ? twoDecimalStringMatch[0] : '';

    return parseFloat(twoDecimalString).toFixed(2);
}

export const isNumeric = (num: any) => (typeof(num) === 'number' || (typeof(num) === "string" && num.trim() !== '')) && !isNaN(num as number);

// check whether the content of array is equal, e.g. ['a,'b'] should be eqaul to ['b', 'a']
export const isStringArrayEqual = (arrA: string[], arrB: string[]) => arrA.length === arrB.length && new Set([...arrA, ...arrB]).size === arrA.length


export const getOdNameLocation = (odString: string) => {
    // Regular expression to match the doctor’s name and optional location
    const regex = /Dr\. ([^\(]+)(?: \(([^)]+)\))?/;
    const match = odString.match(regex);

    if (match) {
        const doctorName = match[1].trim();
        const doctorLocation = match[2] ? match[2].trim() : ''; // Handle empty location
        return [doctorName, doctorLocation];
    } else {
        return ['', '']
    }
}