import type { MenuProps } from 'antd';
import { Menu, Row } from 'antd';
import { useCare1AppDispatch } from '../../apps/care1-hooks';

import { showReportModal } from '../../reducers/admin-reports-slice';

import '../../../static/css/components/patient-list-menu-admin.scss';
import { localizedText } from '../../localizedText';
import AdminReportsModal from './admin-reports-modal';

const PatientListMenuAdmin = () => {
    const {
        ADMIN_ALERT_LISTS_TEXT,
        ADMIN_DOCTERS_TEXT,
        ADMIN_REPORTS_TEXT,
        ADMIN_REPORTS_DASHBOARD_TEXT,
    } = localizedText;

    type MenuItem = Required<MenuProps>['items'][number];

    const dispatch = useCare1AppDispatch();

    const handleClick: MenuProps['onClick'] = ({ key }) => {
        if (key === 'admin') {
            window.open('https://app.care1.ca/admin', '_blank')?.focus();
        } else if (key === 'reports') {
            dispatch(showReportModal());
        } else {
            window.open(`/${key.replaceAll('_', '-')}`);
        }
    };

    const items: MenuItem[] = [
        { label: ADMIN_ALERT_LISTS_TEXT, key: 'alert_lists' },
        { label: 'Smart Uploads', key: 'smart_uploads' },
        { label: 'Review Processing', key: 'review_processing' },
        { label: ADMIN_DOCTERS_TEXT, key: 'doctors' },
        { label: ADMIN_REPORTS_TEXT, key: 'reports' },
        { label: 'Django', key: 'admin' },
        { label: ADMIN_REPORTS_DASHBOARD_TEXT, key: 'reports_dashboard' },
        { label: 'Sales', key: 'sales_dashboard' },
        {
            label: 'QA',
            key: 'qa',
            children: [
                { label: 'OCT RNFL', key: 'oct_rnfl_near_miss' },
                { label: 'VF', key: 'vf_near_miss' },
                { label: 'Exam Metrics', key: 'exam_metrics_near_miss' },
                { label: 'CSV', key: 'csv_near_miss' },
                { label: 'Health Check', key: 'services_health_check' },
            ],
        },
    ];

    return (
        <Row className='patient-list-menu-admin'>
            <AdminReportsModal />
            <Menu
                mode='horizontal'
                items={items}
                onClick={handleClick}
            />
        </Row>
    );
};

export default PatientListMenuAdmin;
