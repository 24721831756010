import { Row, Button, DatePicker, Col, Select, Form, Modal, Tag, Checkbox } from 'antd';
import '../../../../static/css/components/admin-doctor-list-control-bar.scss';
import { buildRequiredErrorMessage } from '../../../helpers/patient-edit-convert';
import { useLazyGetReviewProcessingListQuery, useLazyGetReviewProcessingOdEmailsQuery,
    useLazyGetReviewProcessingOdListQuery, useLazyGetReviewProcessingOmdInternalReviewEmailsQuery
} from '../../../services/review-processing-api';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { IReviewProcessingTaskItem, setReviewProcessingPatientInfoText, setReviewProcessingRequestData, 
    setSelectedOdName, setTaskItems, setSortOrder, setSortedInfo, togglePreviewModal } from '../../../reducers/review-processing-slice';
import { ReportType } from '../../../reducers/patient-exam-slice';
import ReviewProcessingPreviewModal from './review-processing-preview-modal';
import {Dayjs} from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import ReviewProcessingFilterInfo from './review-processing-filter-info';


const ReviewProcessingControlBar = () => {

    const previewModalOpen = useCare1AppSelector(store => store.reviewProcessing.previewModalOpen);
    const selectedRowKeys = useCare1AppSelector(store => store.reviewProcessing.selectedRowKeys);
    const odAiRequestsReport = useCare1AppSelector(store => store.reviewProcessing.odAiRequestsReport);

    const [getReviewProcessingList, {data}] = useLazyGetReviewProcessingListQuery();
    const [getReviewProcessingOdList, {data: odOptions =[]}] = useLazyGetReviewProcessingOdListQuery();
    const [getReviewProcessingOdEmails] = useLazyGetReviewProcessingOdEmailsQuery();
    const [getReviewProcessingOmdInternalReviewEmails] = useLazyGetReviewProcessingOmdInternalReviewEmailsQuery();

    const [form] = Form.useForm();

    const selectedOdId = Form.useWatch('od_id', form);
    const dateRange = Form.useWatch('date', form) ?? [];

    const dispatch = useCare1AppDispatch();

    const handleGenerateList = async (values: {date: Dayjs[], od_id: number | null, od_ai_requests_report: boolean}) => {
        const [startDate, endDate] = values.date;
        dispatch(setReviewProcessingRequestData(values));

        try {
            await getReviewProcessingList({
                start_date: startDate.format('YYYY-MM-DD'),
                end_date: endDate.format('YYYY-MM-DD'),
                od_id: values.od_id ?? '',
                od_ai_requests_report: values.od_ai_requests_report,
            });
    
            await getReviewProcessingOdList({
                start_date: startDate.format('YYYY-MM-DD'),
                end_date: endDate.format('YYYY-MM-DD'),
                od_ai_requests_report: values.od_ai_requests_report,
            });
        } catch (error) {
            const message = (error instanceof Error) ?  error?.message : error;
            Modal.error({
                className: 'info-modal',
                content: `Errors getting Review Processing List, ${message as string}`,
                title: 'Error',
            });
        }
    }

    const handleCompileList = async () => {
        dispatch(togglePreviewModal());
        const selectedOdName = odOptions.find(option => option.key === selectedOdId);
        dispatch(setSelectedOdName(selectedOdName?.label ?? ''));
        odAiRequestsReport ? 
            await getReviewProcessingOdEmails(selectedOdId):
            await getReviewProcessingOmdInternalReviewEmails(selectedOdId);

        if (selectedRowKeys) {
            const premiumRluExams = selectedRowKeys.filter((key) => {
                const patientInfo = data?.find(item => item.key === key);
                if (patientInfo?.rlu_exam_is_premium) {
                    return true;
                }
                return false;
            });
            const taskItems: IReviewProcessingTaskItem[] = premiumRluExams.map(key => {
                const patientInfo = data?.find(item => item.key === key);
                const examId = patientInfo?.exam_id
                return {
                    examId: examId,
                    guid: `${uuidv4()}`,
                }
            });
            dispatch(setTaskItems(taskItems));

            const taskItemsMap : Record<string, string> = {}
            taskItems.forEach((item) => {
                if (item.examId !== undefined) {
                    const k = `${item.examId}`;
                    taskItemsMap[k] = item.guid;
                }
            })

            const patientReviewInfoList = selectedRowKeys.map(key => {
                const patientInfo = data?.find(item => item.key === key);
                
                let firstRowText = `<strong>${patientInfo?.first_name} ${patientInfo?.last_name}</strong>     ${patientInfo?.exam_date}`;
                const secondRowText = `Dr. ${patientInfo?.is_omdr_reviewed ? patientInfo?.omdr_name : patientInfo?.omdc_name} - ${patientInfo?.omd_notes}`;

                // We are temporarily removing the reply link. This will be put back pending more design.
                // const k = `${patientInfo?.exam_id}`;
                // if (k in taskItemsMap) {
                //     const guid = taskItemsMap[k];
                //     const replyLink = `     <a href="${process.env.REACT_APP_FRONTENDURL}/review-processing-reply/${guid}/">Reply to Ophthalmologist</a>`;
                //     firstRowText = firstRowText + replyLink;
                // }

                if (patientInfo?.report_type == ReportType.AI_AND_OMD) {
                    
                    const deepmd_screenshot = patientInfo?.deepmd_screenshot !== '' ? `<img style="max-width: 100%; height: auto" src="${process.env.REACT_APP_PUBLIC_MEDIA_URL}${patientInfo?.deepmd_screenshot}" />` : '';
                    const care1_ai = `<p>Care1 AI</p><p>${deepmd_screenshot}</p><p>${patientInfo?.ai_report}</p>`;
                    const lastRowText = `<p>Dr. ${patientInfo?.omdr_name}</p><p>${patientInfo?.omd_report}</p>`;

                    return {
                        'patient_id': patientInfo?.patient_id,
                        'od_id': patientInfo?.od_id,
                        'text': '<p>' + firstRowText + '</p><p>' + care1_ai + '</p><p>' + lastRowText + '</p>'
                    };
                }
                
                return {
                    'patient_id': patientInfo?.patient_id,
                    'od_id': patientInfo?.od_id,
                    'text': '<p>' + firstRowText + '</p><p>' + secondRowText + '</p>'
                };
            });

            dispatch(setReviewProcessingPatientInfoText(patientReviewInfoList));
        }
    }

    return (
        <Form form={form} onFinish={handleGenerateList}>
            <Row className={'doctor-list-control-bar'} justify={'center'} gutter={[0, 24]}>
                <Col span={24}>
                    <Row justify={'center'} gutter={24} align={'middle'}>
                        <Col span={4} className='omd-review-send-text'>
                            OMD Reviews to Send
                        </Col>
                        
                        <Col span={7}>
                            <Form.Item
                                name='date'
                                rules={[{
                                    required: true,
                                    message: buildRequiredErrorMessage('Date range')
                                }]}
                                initialValue={[]}
                            >
                                <DatePicker.RangePicker
                                    size='large'
                                    />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                name='od_ai_requests_report'
                                initialValue={true}
                                valuePropName='checked'
                                noStyle
                            >
                                <Checkbox className='od-request-text'>OD or AI Requests Report</Checkbox>
                            </Form.Item>
                            <ReviewProcessingFilterInfo />
                        </Col>
                        <Col>
                            <Button
                                type='primary'
                                onClick={() => form.submit()}
                                disabled={dateRange.length !== 2}
                            >Generate List</Button>
                        </Col>
                    </Row>
                    
                </Col>
                <Col span={24}>
                    <Row gutter={24} justify={'center'} align={'middle'}>
                        <Col span={4}>
                            <Form.Item name='od_id' initialValue={null}>
                                <Select
                                    showSearch
                                    options={odOptions}
                                    allowClear
                                    placeholder='ODs'
                                    size='middle'
                                    optionFilterProp='label'
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Button
                                type='primary'
                                onClick={() => {
                                    dispatch(setSortOrder([]));
                                    dispatch(setSortedInfo({}));
                                    }
                                }>Reset Sort Order</Button>
                        </Col>
                        <Col>
                            <Button type='primary' onClick={() => form.submit()}>Reload</Button>
                        </Col>
                        <Col>
                            <Button
                                type='primary'
                                onClick={handleCompileList}
                                disabled={!selectedRowKeys.length}
                            >Compile List to Send</Button>
                        </Col>
                    </Row>
                </Col>
                
            </Row>
            {previewModalOpen && <ReviewProcessingPreviewModal />}
        </Form>
    );
}

export default ReviewProcessingControlBar;