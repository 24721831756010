import { combineReducers } from 'redux';
import { RIGHT_FUNDUS_FIELD, RIGHT_STEREO_FIELD, RIGHT_OCT_RNFL_FIELD, RIGHT_VF_FIELD, RIGHT_OCT_MACULA_FIELD,
    LEFT_FUNDUS_FIELD, LEFT_STEREO_FIELD, LEFT_OCT_RNFL_FIELD, LEFT_VF_FIELD, LEFT_OCT_MACULA_FIELD, EXTRA_IMAGES,
    } from '../constants';
import {dropzoneSlice} from './dropzone-slice';
import patientListSlice from './patient-list-slice';
import patientEditSlice from './patient-edit-slice';
import patientExamSlice from './patient-exam-slice';
import igpListSlice from './igp-list-slice';
import extraImageSlice from './extra-image-slice';
import historyImageSlice from './history-image-slice';
import conversationHistorySlice from './conversation-history-slice';
import diagnosisSlice from './diagnosis-slice';
import helpDeskSlice from './help-desk-slice';
import txalgo3 from './txalgo3-slice';
import usInsurance from './us-insurance-slice';
import letter from './letters-slice';
import adminReviewLists from './admin-review-lists-slice';
import initiateTreatmentReducer from './patient-exam-initiate-treatment-slice';
import adminReportsReducer from './admin-reports-slice';
import doctorSlice from './doctor-slice';
import preReview from './pre-review-slice';
import igpPatientList from './igp-patient-list-slice';
import { baseApi } from '../services/base-api';
import optionsSlice from './options-slice';
import patientDetails from './patient-details-slice'
import patientExams from './patient-exam-rooms-slice';
import userSlice from './user-slice';
import odReviewReminderEmail from './od-review-reminder-email-slice';
import visitsUploadedSlice from './visits-uploaded-slice';
import cataractReferralsSlice from './cataract-referrals-slice';
import maculaAbnormalitySlice from './macula-abnormality-slice';
import maculaHistoricalAnalysisSlice from './macula-historical-analysis-slice';
import referralLetterAnalysisSlice from './referral-letter-analysis-slice';
import salesIntegrationReportSlice from './sales-integration-report-slice';
import octRnflNearMissSlice from './admin-oct-rnfl-near-miss-slice';
import vfNearMissSlice from './admin-vf-near-miss-slice';
import gptVisionSlice from './gpt-vision-slice';
import stopwatchSlice from './stopwatch-slice';
import gptAiSnapshotSlice from './gpt-ai-snapshot-slice';
import aiInsightsHistoricalAnalysisSlice from './ai-insights-historical-analysis-slice';
import gptPatientDemographicsSlice from './gpt-patient-demographics-slice';
import reviewProcessingSlice from './review-processing-slice';
import gptSetValuesSlice from './gpt-set-values-slice';
import diseaseResourceSlice from './disease-resource-slice';
import glaucomaProgramSlice from './glaucoma-program-slice';
import deepMdBandScreenshotSlice from './deep-md-band-screenshot-slice';


const dropzoneReducers = combineReducers({
    right_fundus_photo: dropzoneSlice[RIGHT_FUNDUS_FIELD].reducer,
    right_stereo_photo: dropzoneSlice[RIGHT_STEREO_FIELD].reducer,
    right_oct_photo: dropzoneSlice[RIGHT_OCT_MACULA_FIELD].reducer,
    right_oct_rnfl_photo: dropzoneSlice[RIGHT_OCT_RNFL_FIELD].reducer,
    right_vf_photo: dropzoneSlice[RIGHT_VF_FIELD].reducer,
    left_fundus_photo: dropzoneSlice[LEFT_FUNDUS_FIELD].reducer,
    left_stereo_photo: dropzoneSlice[LEFT_STEREO_FIELD].reducer,
    left_oct_photo: dropzoneSlice[LEFT_OCT_MACULA_FIELD].reducer,
    left_oct_rnfl_photo: dropzoneSlice[LEFT_OCT_RNFL_FIELD].reducer,
    left_vf_photo: dropzoneSlice[LEFT_VF_FIELD].reducer,
    extra_images: dropzoneSlice[EXTRA_IMAGES].reducer,
    cardFrontImage: dropzoneSlice['cardFrontImage'].reducer,
    cardBackImage: dropzoneSlice['cardBackImage'].reducer,
    od_referral_letter: dropzoneSlice.od_referral_letter.reducer,
    // uncomment following code when VF_10_2, OCT Angle, GCC dropzones are added
    // right_vf_10_2_photo: dropzoneSlice[RIGHT_VF_10_2_FIELD].reducer,
    // left_vf_10_2_photo: dropzoneSlice[LEFT_VF_10_2_FIELD].reducer,
    // right_oct_angle_photo: dropzoneSlice[RIGHT_OCT_ANGLE_FIELD].reducer,
    // left_oct_angle_photo: dropzoneSlice[LEFT_OCT_ANGLE_FIELD].reducer,
    // right_gcc_photo: dropzoneSlice[RIGHT_GCC_FIELD].reducer,
    // left_gcc_photo: dropzoneSlice[LEFT_GCC_FIELD].reducer,
});

export default combineReducers({
    user: userSlice,
    patientList: patientListSlice,
    patientDetails,
    patientEdit: patientEditSlice,
    examData: patientExamSlice,
    igpList: igpListSlice,
    dropzones: dropzoneReducers,
    extraImage: extraImageSlice,
    historyImage: historyImageSlice,
    conversationHistory: conversationHistorySlice,
    diagnosis: diagnosisSlice,
    helpDesk: helpDeskSlice,
    patientExams,
    txalgo3: txalgo3,
    initiateTreatment: initiateTreatmentReducer,
    usInsurance,
    letter,
    adminReviewLists,
    adminReports: adminReportsReducer,
    doctorSlice,
    preReview,
    igpPatientList,
    options: optionsSlice,
    odReviewReminderEmail,
    [baseApi.reducerPath]: baseApi.reducer,
    visitsUploaded: visitsUploadedSlice,
    cataractReferrals: cataractReferralsSlice,
    maculaAbnormality: maculaAbnormalitySlice,
    maculaHistoricalAnalysis: maculaHistoricalAnalysisSlice,
    referralLetterAnalysis: referralLetterAnalysisSlice,
    salesIntegrationReport: salesIntegrationReportSlice,
    octRnflNearMiss: octRnflNearMissSlice,
    vfNearMiss: vfNearMissSlice,
    gptVision: gptVisionSlice,
    stopwatch: stopwatchSlice,
    gptAiSnapshot: gptAiSnapshotSlice,
    aiInsightsHistoricalAnalysis: aiInsightsHistoricalAnalysisSlice,
    gptPatientDemographics: gptPatientDemographicsSlice,
    reviewProcessing: reviewProcessingSlice,
    gptSetValues: gptSetValuesSlice,
    diseaseResource: diseaseResourceSlice,
    glaucomaProgram: glaucomaProgramSlice,
    deepMdBandScreenshot: deepMdBandScreenshotSlice,
});
