import { useCare1AppSelector, useCare1AppDispatch } from '../../apps/care1-hooks';
import { Form, Typography, Select, Flex, Tooltip } from 'antd';

import { useGetDefaultOmdQuery, useGetOmdOptionsQuery } from '../../services/doctor-api';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import OutboundOmdEditModal from '../admin/outbound-omd-edit-modal';
import { toggleOutboundOmdAddModal, toggleOutboundOmdEditModal } from '../../reducers/doctor-slice';
import OutboundOmdAddModal from '../admin/outbound-omd-add-modal';


const PatientExamBillingRulesReferralOmd = ({ disabled }: { disabled: boolean }) => {

    const isOutboundReferral = useCare1AppSelector(store => store.examData.is_outbound_referral);
    const referralOmd = useCare1AppSelector(store => store.examData.referral_omd);

    const examId = useCare1AppSelector(store => store.examData.id);
    const odId = useCare1AppSelector(store => store.examData.optometrist);
    const dispatch = useCare1AppDispatch();

    const {data: omdOptions} = useGetOmdOptionsQuery(examId!, {skip: !isOutboundReferral});
    useGetDefaultOmdQuery(odId!, {skip: (!isOutboundReferral || !odId || Boolean(referralOmd))});


    return (
        <>
            <Form.Item
                label= {
                    <Flex gap='small'>
                        <div>Send Referral To</div>
                        <Tooltip title='Add New OMD'>
                            <PlusOutlined
                                className='referral-omd-add-button'
                                onClick={() => dispatch(toggleOutboundOmdAddModal())}
                            />
                        </Tooltip>
                        { referralOmd && !disabled &&
                            <Tooltip title='Edit OMD'>
                                <EditOutlined
                                    className='referral-omd-edit-button'
                                    onClick={() => dispatch(toggleOutboundOmdEditModal())} 
                                />
                            </Tooltip>
                        }
                    </Flex>
                }
                name='referral_omd'
                rules={[{ required: true }]}
                labelCol={{span: 9}}
                wrapperCol={{span: 15}}
            >
                <Select
                    placeholder='Search or Enter OMD'
                    options={omdOptions}
                    showSearch
                    allowClear
                    optionFilterProp='label'
                    disabled={disabled}
                    notFoundContent={
                        <Typography.Text style={{color: '#bfdfff'}}>
                            No matching ophthalmologist found. Consider adding a new OMD.
                        </Typography.Text>}
                    />
            </Form.Item>
            <OutboundOmdEditModal />
            <OutboundOmdAddModal />
        </>    
    );
}

export default PatientExamBillingRulesReferralOmd;