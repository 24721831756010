import { useState, useEffect } from "react";
import { Row, Col, Tooltip, Input, Checkbox, Card } from 'antd';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { setAISnapshotDataValue } from '../../../reducers/gpt-ai-snapshot-slice';
import { IExamData, setExamDataValue } from '../../../reducers/patient-exam-slice';
import { InfoCircleOutlined } from '@ant-design/icons';
import PatientExamODNotes from '../../../components/integrated/patient-exam-od-notes';

import '../../../../static/css/components/admin-gpt-ai-snapshot-band.scss';

type ComponentProps = {
    snapshotType: string,
    impressions: string[],
    odAssessment: string,
    odQuestion: string,
    impressionsField: string,
    odAssessmentField: string,
    odQuestionField: string,
    examODNotesField: string,
    getAiSnapshotBandValues: () => void,
};

const GptAISnapshotBand = ({ snapshotType, impressions, odAssessment, odQuestion, 
    impressionsField, odAssessmentField, odQuestionField, getAiSnapshotBandValues } : ComponentProps) => {

    const dispatch = useCare1AppDispatch();

    const userIsADMIN = useCare1AppSelector(store => store.user.isADMIN);

    const [impressionsText, setImpressionsText] = useState('');
    const [odAssessmentText, setOdAssessmentText] = useState('');
    const [odQuestionText, setOdQuestionText] = useState('');

    // Checkbox for admin to toggle in and out of editing mode.
    const [adminIsEditingImpressionsText, setAdminIsEditingImpressionsText] = useState(false);
    const [adminIsEditingOdAssessmentText, setAdminIsEditingOdAssessmentText] = useState(false);
    const [adminIsEditingOdQuestionText, setAdminIsEditingOdQuestionText] = useState(false);

    const examStatusPrevious = useCare1AppSelector(store => store.examData.exam_status_previous);

    const isOMDReadyOrReviewed = examStatusPrevious === 'ready' || examStatusPrevious === 'reviewed';

    const odGroupPracticeProvince = useCare1AppSelector(store => store.examData.od_group_practice_province);
    const isManitoba = odGroupPracticeProvince === 'MB';

    useEffect(() => {
        setImpressionsText(impressions.join('\n'));
    }, [impressions])

    useEffect(() => {
        setOdAssessmentText(odAssessment);
    }, [odAssessment])

    useEffect(() => {
        setOdQuestionText(odQuestion);
    }, [odQuestion])

    useEffect(() => {
        getAiSnapshotBandValues();
        // eslint-disable-next-line
    }, []);

    return (<>
        <Row className="gpt-ai-snapshot-band">
            <Col span={24}>
            <span className='title'>{snapshotType} AI Snapshot</span>
            <span className='title-tooltip'>
                <Tooltip
                    className={'recommendations-tooltip'}
                    placement='right'
                    title="This section is generated entirely by AI reading the uploaded referral letter, and may not be accurate. Please utilize at your discretion."
                >
                    <InfoCircleOutlined />
                </Tooltip>
            </span>
            </Col>
        </Row>
        <Row className="gpt-ai-snapshot-band">
            <Col span={8}>
                <Card className={''} bordered={false}>
                <div className="heading">
                    Impression
                    { userIsADMIN &&
                            <Checkbox
                                onChange={()=> setAdminIsEditingImpressionsText(!adminIsEditingImpressionsText)}
                                className='admin-edit-checkbox'
                            >
                                Admin Edit
                            </Checkbox>
                    }
                </div>
                    { !adminIsEditingImpressionsText &&
                        <div className="component-input">
                            {
                                impressionsText && impressionsText.split('\n').map(
                                    (string, index) => <div className="impression-multi-value">{string}</div>
                                )
                            }
                            {
                                impressionsText === '' && 
                                    <div>No impression detected.</div>
                            }
                        </div>
                    }
                    { adminIsEditingImpressionsText &&
                        <div className="component-input">
                        <Input.TextArea
                            autoSize={false}
                            value={impressionsText}
                            onChange={(e) => {
                                setImpressionsText(e.target.value);
                                dispatch(setAISnapshotDataValue({key: impressionsField, value: e.target.value.split('\n')}));
                            }}
                        />
                        </div>
                    }
                </Card>
            </Col>
            <Col span={8}>
                <Card className={''} bordered={false}>
                <div className="heading">
                Plan
                    { userIsADMIN &&
                            <Checkbox
                                onChange={()=> setAdminIsEditingOdAssessmentText(!adminIsEditingOdAssessmentText)}
                                className='admin-edit-checkbox'
                            >
                                Admin Edit
                            </Checkbox>
                    }
                </div>
                    { !adminIsEditingOdAssessmentText && odAssessmentText !== '' && 
                        <div className="component-input">
                            {odAssessmentText}
                        </div>
                    }
                    { !adminIsEditingOdAssessmentText && odAssessmentText === '' && 
                        <div className="component-input">
                            No Plan detected.
                        </div>
                    }
                    { adminIsEditingOdAssessmentText &&
                        <div className="component-input">
                        <Input.TextArea
                            autoSize={false}
                            value={odAssessmentText}
                            onChange={(e) => {
                                setOdAssessmentText(e.target.value);
                                dispatch(setAISnapshotDataValue({key: odAssessmentField, value: e.target.value}));
                            }}
                        />
                        </div>
                    }
                </Card>
            </Col>
            <Col span={4}>   
                <div className="admin-patient-exam-od-notes">
                    <PatientExamODNotes className={isManitoba ? 'manitoba-od-notes input-od-notes' : 'input-od-notes'} disabled={isOMDReadyOrReviewed}
                                                    odNotesTitle={'OD Notes'} />
                </div>                 
            </Col>
            <Col span={4}>
                <Card className={'ai-snapshot-last'} bordered={false}>
                <div className="heading gpt-ai-snapshot-band-heading">
                    OD Question
                    { userIsADMIN &&
                            <Checkbox
                                onChange={()=> setAdminIsEditingOdQuestionText(!adminIsEditingOdQuestionText)}
                                className='admin-edit-checkbox'
                            >
                                Admin Edit
                            </Checkbox>
                    }
                </div>
                    { !adminIsEditingOdQuestionText && odQuestionText !== '' &&
                        <div className="component-input-small od-question-detected">
                            {odQuestionText}
                        </div>
                    }
                    { !adminIsEditingOdQuestionText && odQuestionText === '' &&
                        <div className="component-input-small">
                            No OD question detected.
                        </div>
                    }
                    { adminIsEditingOdQuestionText &&
                        <div className="component-input-small">
                        <Input.TextArea
                            autoSize={false}
                            value={odQuestionText}
                            onChange={(e) => {
                                setOdQuestionText(e.target.value);
                                dispatch(setAISnapshotDataValue({ key: odQuestionField, value: e.target.value}));
                            }}
                        />
                        </div>
                    }
                </Card>
            </Col>
        </Row>
    </>);
}

export default GptAISnapshotBand;