import { useCallback } from 'react';
import sanitizeHtml from "sanitize-html";
import ContentEditable from 'react-contenteditable';
import { Modal, Button, Descriptions, Row, Typography, ConfigProvider, theme, Card, Input } from 'antd';

// Action imports
import { EditOutlined, MailOutlined, MoonOutlined, SunOutlined } from '@ant-design/icons';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { setEmailSubject, setReviewProcessingPatientInfoText, setTemplateText, toggleEmailConfirmationModal, togglePreviewModal, toggleReviewProcessingModalTheme } from '../../../reducers/review-processing-slice';
import { DescriptionsProps } from 'antd/lib';
import EmailTags from './email-tags';
import EmailConfirmModal from './email-confirm-modal';
import { isDeleteExpression } from 'typescript';

const ReviewProcessingPreviewModal = () => {

    const isPreviewModalOpen = useCare1AppSelector(store => store.reviewProcessing.previewModalOpen);
    const isDarkTheme = useCare1AppSelector(store => store.reviewProcessing.isDarkTheme);
    const startDate = useCare1AppSelector(store => store.reviewProcessing.startDate);
    const endDate = useCare1AppSelector(store => store.reviewProcessing.endDate);
    const selectedRowKeys = useCare1AppSelector(store => store.reviewProcessing.selectedRowKeys);
    const selectedOdName = useCare1AppSelector(store => store.reviewProcessing.selectedOdName);
    const emailSubject = useCare1AppSelector(store => store.reviewProcessing.emailSubject);
    const patientReviewInfoText = useCare1AppSelector(store => store.reviewProcessing.patientReviewInfoText);
    const templateText = useCare1AppSelector(store => store.reviewProcessing.templateText);
    const odAiRequestsReport = useCare1AppSelector(store => store.reviewProcessing.odAiRequestsReport);

    const dispatch = useCare1AppDispatch();

	const onContentChange = useCallback((idx: number, evt: { currentTarget: { innerHTML: string; }; }) => {
		const sanitizeConf = {
			allowedTags: ["b", "i", "a", "p", "br", "img"],
			allowedAttributes: { a: ["href"] }
		};

        const html = evt.currentTarget.innerHTML;

        const patientReviewInfoTextCopy = [...patientReviewInfoText];
        patientReviewInfoTextCopy[idx] = {
            patient_id: patientReviewInfoText[idx].patient_id,
            od_id: patientReviewInfoText[idx].od_id,
            text: sanitizeHtml(html, sanitizeConf)
        };

        dispatch(setReviewProcessingPatientInfoText(patientReviewInfoTextCopy));
	}, [])

    const renderPatientReviewInfoText = (idx: number) => {
        return patientReviewInfoText[idx].text;
    }

    const closeButton = (
        <Button
            key='close-button'
            onClick={() => dispatch(togglePreviewModal())}
        >
            Close
        </Button>
    );

    const sendEmailButton = (
        <Button
            key='save-button'
            icon={<MailOutlined />}
            type='primary'
            style={{height: '2rem'}}
            onClick={() => dispatch(toggleEmailConfirmationModal())}
        >
            Send Reviews Email
        </Button>
    );

    const items: DescriptionsProps['items'] = [
        {
          key: '1',
          label: 'Review For',
          children: `Dr. ${selectedOdName}`,
        },
        {
          key: '2',
          label: 'Total Reviews to be sent',
          children: selectedRowKeys.length,
          span: 2,
        },
        {
          key: '3',
          label: 'OMD Reviews from',
          children: `${startDate} to ${endDate}`,
          span: 3,
        },
        {
          key: '4',
          label: 'To',
          children: <EmailTags />,
          span: 3,
        },
        {
            key: '5',
            label: 'Subject',
            children: <Typography.Text 
                style={{ width: '230px'}}
                editable={{
                    onChange: (value) => {
                        dispatch(setEmailSubject(value));
                    },
                    maxLength: 300,
                    autoSize: {maxRows: 1},
                    icon :<EditOutlined style={{color: isDarkTheme ? 'rgb(191,223,255)': ''}} />,
            }}>{emailSubject}</Typography.Text>,
            span: 3,
          },
      ];
      
    return (
        <ConfigProvider theme={{
            algorithm: isDarkTheme ? theme.darkAlgorithm : theme.defaultAlgorithm,
            token: {
                colorTextTertiary: isDarkTheme ? 'rgba(255,255,255, 0.9)' : '',
                colorText: isDarkTheme ? 'rgb(255,255,255)': '',
            },
        }}>
            <Modal
                className=''
                open={isPreviewModalOpen}
                closable={false}
                footer={[closeButton, sendEmailButton]}
                keyboard={true}
                width={960}
                zIndex={998}
                styles={{
                    body:{
                        height: '900px'
                    }
                }}
            >
                <Row justify='end'>
                    <Button
                        icon={isDarkTheme ? <SunOutlined /> : <MoonOutlined />}
                        shape='circle'
                        onClick={() => dispatch(toggleReviewProcessingModalTheme())}
                    />
                </Row>
                <Descriptions items={items} />
                <Card style={{height: '650px', overflowY: 'scroll'}}>
                    <Row>
                        <Input.TextArea
                            value={templateText}
                            style={{height: odAiRequestsReport ? '200px': '54px', marginBottom: '12px'}}
                            onChange={(e) => dispatch(setTemplateText(e.target.value))} />
                    </Row>
                    <Row style={{height: '400px', overflowY: 'scroll'}}>
                        {patientReviewInfoText && patientReviewInfoText.map((info, idx) => (
                            <ContentEditable
                                key={idx}
                                className='content-editable'
                                onChange={(evt: { currentTarget: { innerHTML: string; }; }) => onContentChange(idx, evt)}
                                onBlur={(evt: { currentTarget: { innerHTML: string; }; }) => onContentChange(idx, evt)}
                                html={renderPatientReviewInfoText(idx)} />
                        ))}
                    </Row>
                </Card>
                <EmailConfirmModal />
            </Modal>
        </ConfigProvider>
    );
}

export default ReviewProcessingPreviewModal;
