import { Col, Row } from 'antd';

type Note = {
    color: string;
    content: string;
};

type ComponentProps = {
    type: string;
    side: string;
    date: string;
    isProposedPlan?: boolean;
    showAdminView?: boolean;
    odName?: string;
    omdcName?: string;
    odProposedPlan?: string;
    odOMDText?: string;
    omdNotesText?: string | Note[];
    omdrName?: string;
    adminNotesText?: string;
    odWantsOmdReport?: boolean;
    aiReportText?: string;
    omdReportText?: string;
};

function OMDODNotesBubble({
    type,
    isProposedPlan,
    showAdminView,
    side,
    date,
    odName,
    omdcName,
    odProposedPlan,
    odOMDText,
    omdNotesText,
    omdrName,
    adminNotesText,
    odWantsOmdReport,
    aiReportText,
    omdReportText,
}: ComponentProps) {
    let bubbleClass = '';
    const headerClass = 'bubble-header heading';
    const headerDate = 'bubble-header-date';
    const colSpan = 23;
    if (type === 'OD') {
        bubbleClass = 'od-notes-bubble';
        if (isProposedPlan) {
            return (
                <Col
                    span={colSpan}
                    offset={1}
                    className={`od-notes-${side.toLowerCase()}`}
                >
                    <div className={headerClass}>
                        <span className={headerDate}>{date}</span>
                        <span className='doctor-name'>OD Dr. {odName}</span>
                    </div>
                    <Row className={bubbleClass}>
                        <div>{odProposedPlan}</div>
                    </Row>
                </Col>
            );
        }
        return (
            <Col
                span={colSpan}
                offset={1}
                className={`od-notes-${side.toLowerCase()}`}
            >
                <div className={headerClass}>
                    <span className={headerDate}>{date}</span>
                    <span className='doctor-name'>OD Dr. {odName}</span>
                </div>
                <Row className={bubbleClass}>
                    <div>{odOMDText}</div> <br /> {odProposedPlan}
                </Row>
            </Col>
        );
    } else if (type === 'OMDC') {
        if (showAdminView) {
            bubbleClass = 'omdc-admin-notes-bubble';
        } else {
            bubbleClass = 'omdc-notes-bubble';
        }

        return (
            <Col span={colSpan} className={`omd-notes-${side.toLowerCase()}`}>
                <div className={`${headerClass} omdc`}>
                    <span className='doctor-name'>{omdcName}</span>
                    <span className={headerDate}>{date}</span>
                </div>
                <Row className={bubbleClass}>
                    {!showAdminView && typeof omdNotesText === 'string' && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: String(omdNotesText),
                            }}
                        />
                    )}
                    {showAdminView &&
                        Array.isArray(omdNotesText) &&
                        omdNotesText.map((note: Note, idx: number) => (
                            <span
                                key={idx}
                                className={`note-str-color-${note['color']}`}
                            >
                                {note['content']}
                            </span>
                        ))}
                </Row>
            </Col>
        );
    } else if (type === 'OMDR') {
        if (showAdminView) {
            bubbleClass = 'omd-admin-notes-bubble';
        } else {
            bubbleClass = 'omd-notes-bubble';
        }

        return (
            <Col span={colSpan} className={`omd-notes-${side.toLowerCase()}`}>
                <div className={headerClass}>
                    <span className='doctor-name'>{omdrName}</span>
                    <span className={headerDate}>{date}</span>
                </div>
                <Row className={bubbleClass}>
                    {!showAdminView && typeof omdNotesText === 'string' && (
                        <>
                            {odWantsOmdReport === false && (
                                <span style={{ color: 'green' }}>
                                    *** AI Validation Review ***
                                </span>
                            )}
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: String(omdNotesText),
                                }}
                            />
                        </>
                    )}
                    {showAdminView &&
                        Array.isArray(omdNotesText) &&
                        omdNotesText.map((note, idx) => (
                            <span
                                key={idx}
                                className={`note-str-color-${note['color']}`}
                            >
                                {note['content']}
                            </span>
                        ))}
                </Row>
            </Col>
        );
    } else if (type === 'ADMIN') {
        bubbleClass = 'od-notes-bubble';

        return (
            <Col span={colSpan} className={`od-notes-${side.toLowerCase()}`}>
                <div className={headerClass}>
                    <span className='doctor-name'>
                        In person consultation status
                    </span>
                    <span className={headerDate}>{date}</span>
                </div>
                <Row className={bubbleClass}>{adminNotesText}</Row>
            </Col>
        );
    } else if (type === 'AI_REPORT') {
        bubbleClass = 'omd-notes-bubble ai-report-bubble';

        return (
            <Col span={colSpan} className={`omd-notes-${side.toLowerCase()} ai-report`}>
                <div className={headerClass}>
                    <span className='doctor-name'>Care1 AI</span>
                    <span className={headerDate}>{date}</span>
                </div>
                <Row className={bubbleClass}>{aiReportText}</Row>
            </Col>
        );
    } else if (type === 'OMD_REPORT') {
        bubbleClass = 'omd-notes-bubble';

        return (
            <Col span={colSpan} className={`omd-notes-${side.toLowerCase()}`}>
                <div className={headerClass}>
                    <span className='doctor-name'>{omdrName}</span>
                    <span className={headerDate}>{date}</span>
                </div>
                <Row className={bubbleClass}>
                    {odWantsOmdReport === false && (
                        <span style={{ color: 'green' }}>
                            *** AI Validation Review ***
                        </span>
                    )}
                    {omdReportText}
                </Row>
            </Col>
        );
    } else {
        return null;
    }
}

export default OMDODNotesBubble;
