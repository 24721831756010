import { useEffect, useCallback } from 'react';
import { Col, Row, Layout, Card, Divider } from 'antd';
import { useCare1AppSelector, useCare1AppDispatch } from '../apps/care1-hooks';
import { useParams } from 'react-router';
import { shallowEqual } from 'react-redux';

import * as Constants from '../constants';
import DeepMdOdGlcFeatures from '../components/integrated/deepMdOd/deepmd-od-glc-features';
import DeepMdOdBaselineTesting from '../components/integrated/deepMdOd/deepmd-od-baseline-testing';
import DeepMdOdMedicalOpinion from '../components/integrated/deepMdOd/deepmd-od-medical-opinion';
import { lengthEqual } from '../reducers/patient-exam-slice';
import { getMdBandData } from '../reducers/deep-md-band-screenshot-slice';
import '../../static/css/components/deepmd-od-band.scss';

const { Content } = Layout;


const DeepMdBandScreenshotPage = () => {

    const dispatch = useCare1AppDispatch();
    const params = useParams();

    const suspFeatures = useCare1AppSelector(store => store.deepMdBandScreenshot.susp_features, shallowEqual);
    const reasFeatures = useCare1AppSelector(store => store.deepMdBandScreenshot.reas_features, shallowEqual);

    const examsWithLeftVF = useCare1AppSelector(store => store.deepMdBandScreenshot.exams_with_left_vf, lengthEqual);
    const examsWithRightVF = useCare1AppSelector(store => store.deepMdBandScreenshot.exams_with_right_vf, lengthEqual);
    const examsWithLeftRNFL = useCare1AppSelector(store => store.deepMdBandScreenshot.exams_with_left_oct_rnfl, lengthEqual);
    const examsWithRightRNFL = useCare1AppSelector(store => store.deepMdBandScreenshot.exams_with_right_oct_rnfl, lengthEqual);
    const examsWithIOPLeftIOP = useCare1AppSelector(store => store.deepMdBandScreenshot.graph_iop?.os_iop_points ?? [], lengthEqual);
    const examsWithIOPRightIOP = useCare1AppSelector(store => store.deepMdBandScreenshot.graph_iop?.od_iop_points ?? [], lengthEqual);

    const rightIopComfortZone = useCare1AppSelector(store => store.deepMdBandScreenshot.txalgo3?.od_iop_aim);
    const worstRecClass = useCare1AppSelector(store => store.deepMdBandScreenshot.txalgo3?.class_aim);
    const shortestRecFu = useCare1AppSelector(store => store.deepMdBandScreenshot.txalgo3?.followup_aim);
    const leftIopComfortZone = useCare1AppSelector(store => store.deepMdBandScreenshot.txalgo3?.os_iop_aim);

    const fuPeriods = useCare1AppSelector(store => store.deepMdBandScreenshot.fu_periods);

    const isGlc = useCare1AppSelector(store => store.deepMdBandScreenshot.rr_glc);
    const isGlcSuspect = useCare1AppSelector(store => store.deepMdBandScreenshot.rr_glc_suspect);

    const editExam = useCallback(() => {
        const exam_id = Number(params[Constants.LATEST_EXAM_ID_FIELD]) || null;
        if (exam_id !== null) {
            dispatch(getMdBandData(`${exam_id}`));
        }
    }, [params, dispatch])

    useEffect(() => {
        editExam();
    }, [editExam])

    return (
        <Layout className='deep-md-band-screenshot-page' id='deep-md-band-screenshot-page'>
            <Content>
                <Card className='od-deepmd-band od-deepmd-screenshot' bordered={false}>
                    <Row className='full-band'>
                        <Col>
                            <DeepMdOdGlcFeatures
                                suspFeatures={suspFeatures}
                                reasFeatures={reasFeatures}
                            />
                        </Col>
                        <Divider className={'divider'} type="vertical" />
                        {(isGlc || isGlcSuspect) &&
                            <>
                                <Col >
                                    <DeepMdOdBaselineTesting
                                        examsWithLeftVF={examsWithLeftVF}
                                        examsWithRightVF={examsWithRightVF}
                                        examsWithLeftRNFL={examsWithLeftRNFL}
                                        examsWithRightRNFL={examsWithRightRNFL}
                                        examsWithIOPLeftIOP={examsWithIOPLeftIOP}
                                        examsWithIOPRightIOP={examsWithIOPRightIOP}
                                    />
                                </Col>
                                <Divider className={'divider'} type="vertical" />
                                <Col>
                                    <DeepMdOdMedicalOpinion
                                        rightIopComfortZone={rightIopComfortZone}
                                        worstRecClass={worstRecClass}
                                        shortestRecFu={shortestRecFu}
                                        leftIopComfortZone={leftIopComfortZone}
                                        fuPeriods={fuPeriods}
                                    />
                                </Col>
                            </>
                        }
                    </Row>
                </Card>
            </Content>
        </Layout>
    );
}

export default DeepMdBandScreenshotPage;
