import React, { useEffect, useState } from 'react';
import { Table, Tooltip, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import '../../../../static/css/components/admin-doctor-list-table.scss';
import { compareStrings, sortDates } from '../../../helpers/sorting';
import { IReviewProcessingTableItem, useGetReivewProcessingListQueryState } from '../../../services/review-processing-api';
import { SorterResult } from 'antd/es/table/interface';
import { PATIENT_EXAM_URL } from '../../../constants';
import { goToExamInNewTab } from '../../../reducers/patient-exam-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { setSelectedRowKeys, setSortOrder, setSortedInfo } from '../../../reducers/review-processing-slice';


const ReviewProcessingTable = () => {

    const selectedRowKeys = useCare1AppSelector(store => store.reviewProcessing.selectedRowKeys);
    const sortOrder = useCare1AppSelector(store => store.reviewProcessing.sortOrder);
    const sortedInfo = useCare1AppSelector(store => store.reviewProcessing.sortedInfo);
    const startDate = useCare1AppSelector(store => store.reviewProcessing.startDate);
    const endDate = useCare1AppSelector(store => store.reviewProcessing.endDate);
    const odAiRequestsReport = useCare1AppSelector(store => store.reviewProcessing.odAiRequestsReport);
    const odId = useCare1AppSelector(store => store.reviewProcessing.odId);

    const {data, isFetching} = useGetReivewProcessingListQueryState({
        start_date: startDate,
        end_date: endDate,
        od_id: odId ?? '',
        od_ai_requests_report: odAiRequestsReport,
    });

    const dispatch = useCare1AppDispatch();

    const [scrollClass, setScrollClass] = useState('');

    // only run when the component first mount
    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        // cleanup function
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [])

    const onScroll = () => {
        if (window.scrollY > 80) {
            setScrollClass('scroll');
        } else {
            setScrollClass('');
        }
    }

    const handleChange = (sorter: SorterResult<IReviewProcessingTableItem> | SorterResult<IReviewProcessingTableItem>[]) => {
        dispatch(setSortedInfo(sorter));

        const lastSorterItem = Array.isArray(sorter) ? [...sorter].pop() : sorter;
        if (!lastSorterItem) return;
        if (lastSorterItem.order === 'ascend' || lastSorterItem.order === 'descend') {
            if (!sortOrder.includes(lastSorterItem.columnKey as string)) {
                // append the key to the front of list so that it has smaller priority
                dispatch(setSortOrder([lastSorterItem.columnKey as string, ...sortOrder]))
            }
        } else if (lastSorterItem.order === undefined) {
            dispatch(setSortOrder(sortOrder.filter(key => key !== lastSorterItem.columnKey)));
        }
    }

    // Each column needs to get the current state of its sortOrder
    const getSortOrder = (key: string) => {
        // If only one column is sorted, sortedInfo is an object.
        // If two or more columns are sorted, sortedInfo is an array.
        if(Array.isArray(sortedInfo)){
            const sortOrderItem = sortedInfo.find(so => so.columnKey === key);
            return sortOrderItem ? sortOrderItem.order : null;
        } else {
            return sortedInfo?.columnKey === key ? sortedInfo.order : null
        }
    }

    // Open the exam in a new tab and stay on the patient list page.
    // This achieved by using the Force Background Chrome extension
    const onTableRowClick = (record: IReviewProcessingTableItem) => {
        const examUrl = `${PATIENT_EXAM_URL}/${record.exam_id}`;
        goToExamInNewTab(examUrl);
    }

    
    const columns: ColumnsType<IReviewProcessingTableItem> = [
        {
            key: 'exam_status',
            className: 'review-processing-column',
            title: 'Exam Status',
            dataIndex: 'exam_status',
            sorter: {
                compare: (a,b) => compareStrings(a['exam_status'], b['exam_status']),
                multiple: sortOrder.indexOf('exam_status'),
            },
            sortOrder: getSortOrder('exam_status'),
            width: 130,
        }, {
            key: 'od_name',
            className: 'review-processing-column',
            title: 'Exam OD',
            dataIndex: 'od_name',
            sorter: {
                compare: (a,b) => compareStrings(a['od_name'], b['od_name']),
                multiple: sortOrder.indexOf('od_name'),
            },
            sortOrder: getSortOrder('od_name'),
            width: 120,
        }, {
            key: 'first_name',
            className: 'review-processing-fname-column',
            title: 'First Name',
            dataIndex: 'first_name',
            sorter: {
                compare: (a,b) => compareStrings(a['first_name'], b['first_name']),
                multiple: sortOrder.indexOf('first_name'),
            },
            sortOrder: getSortOrder('first_name'),
            width: 140,
        },
        {
            key: 'last_name',
            className: 'review-processing-lname-column',
            title: 'Last Name',
            dataIndex: 'last_name',
            sorter: {
                compare: (a,b) => compareStrings(a['last_name'], b['last_name']),
                multiple: sortOrder.indexOf('last_name'),
            },
            sortOrder: getSortOrder('last_name'),
            width: 140,
        }, {
            key: 'exam_date',
            className: 'review-processing-column',
            title: 'Exam Visit Date',
            dataIndex: 'exam_date',
            sorter: {
                compare: (a,b) => sortDates(a['exam_date'], b['exam_date']),
                multiple: sortOrder.indexOf('exam_date'),
            },
            sortOrder: getSortOrder('exam_date'),
            width: 150,
        }, {
            key: 'omdr_name',
            className: 'review-processing-column',
            title: 'OMDR',
            dataIndex: 'omdr_name',
            sorter: {
                compare: (a,b) => compareStrings(a['omdr_name'], b['omdr_name']),
                multiple: sortOrder.indexOf('omdr_name'),
            },
            sortOrder: getSortOrder('omdr_name'),
            width: 100,
        }, {
            key: 'omdc_name',
            className: 'review-processing-column',
            title: 'OMDC',
            dataIndex: 'omdc_name',
            sorter: {
                compare: (a,b) => compareStrings(a['omdc_name'], b['omdc_name']),
                multiple: sortOrder.indexOf('omdc_name'),
            },
            sortOrder: getSortOrder('omdc_name'),
            width: 100,
        }, {
            key: 'alert',
            className: 'review-processing-column',
            title: 'Alert',
            dataIndex: 'alert',
            sorter: {
                compare: (a,b) => compareStrings(a['alert'], b['alert']),
                multiple: sortOrder.indexOf('alert'),
            },
            sortOrder: getSortOrder('alert'),
            width: 80,
        }, {
            key: 'omd_notes',
            className: 'review-processing-column',
            title: 'OMD Notes',
            dataIndex: 'omd_notes',
            width: 400,
            render: (value, record) =>
                <Typography.Paragraph
                    ellipsis={{
                        rows: 2,
                        expandable: true,
                        symbol: 'more',
                        tooltip: {
                            title: value,
                            overlayInnerStyle: {width: '400px', whiteSpace: 'pre-line',},
                        }}}
                >{value}
                </Typography.Paragraph>,
            onCell: (record) => {
                return {
                    onClick: (event) => event.stopPropagation()
                }
            }
        }, {
            key: 'od_province',
            className: 'review-processing-column',
            title: 'OD Province',
            dataIndex: 'od_province',
            sorter: {
                compare: (a,b) => compareStrings(a['od_province'], b['od_province']),
                multiple: sortOrder.indexOf('od_province'),
            },
            sortOrder: getSortOrder('od_province'),
            width: 130,
        }, {
            key: 'od_group_practice_name',
            className: 'review-processing-column',
            title: 'OD Group Practice',
            dataIndex: 'od_group_practice_name',
            sorter: {
                compare: (a,b) => compareStrings(a['od_group_practice_name'], b['od_group_practice_name']),
                multiple: sortOrder.indexOf('od_group_practice_name'),
            },
            sortOrder: getSortOrder('od_group_practice_name'),
            width: 170
        }, {
            key: 'last_omd_reviewed_date',
            className: 'review-processing-column',
            title: 'Last OMD Reviewed Date',
            dataIndex: 'last_omd_reviewed_date',
            sorter: {
                compare: (a,b) => sortDates(a['last_omd_reviewed_date'], b['last_omd_reviewed_date']),
                multiple: sortOrder.indexOf('last_omd_reviewed_date'),
            },
            sortOrder: getSortOrder('last_omd_reviewed_date'),
            width: 200,
        }, {
            key: 'email_status',
            className: 'email-status-column',
            title: 'Email Status',
            dataIndex: 'email_status',
            sorter: {
                compare: (a,b) => compareStrings(String(a['email_status']), String(b['email_status'])),
                multiple: sortOrder.indexOf('email_status'),
            },
            sortOrder: getSortOrder('email_status'),
            width: 150,
        }, {
            key: 'is_review_processed',
            className: 'review-processing-column',
            title: 'Is Review Processed',
            dataIndex: 'is_review_processed',
            render: (value) => value ? 'Yes' : 'No',
            sorter: {
                compare: (a,b) => compareStrings(String(a['is_review_processed']), String(b['is_review_processed'])),
                multiple: sortOrder.indexOf('is_review_processed'),
            },
            sortOrder: getSortOrder('is_review_processed'),
            width: 170,
        }, {
            key: 'od_wants_omd_report',
            className: 'review-processing-column',
            title: 'OD Wants OMD Report',
            dataIndex: 'od_wants_omd_report',
            render: (value) => value ? 'Yes' : 'No',
            sorter: {
                compare: (a,b) => compareStrings(String(a['od_wants_omd_report']), String(b['od_wants_omd_report'])),
                multiple: sortOrder.indexOf('od_wants_omd_report'),
            },
            sortOrder: getSortOrder('od_wants_omd_report'),
            width: 180,
        }, {
            key: 'ai_recommends_omd_report',
            className: 'review-processing-column',
            title: 'AI Recommends OMD Report',
            dataIndex: 'ai_recommends_omd_report',
            render: (value) => value ? 'Yes' : 'No',
            sorter: {
                compare: (a,b) => compareStrings(String(a['ai_recommends_omd_report']), String(b['ai_recommends_omd_report'])),
                multiple: sortOrder.indexOf('ai_recommends_omd_report'),
            },
            sortOrder: getSortOrder('ai_recommends_omd_report'),
            width: 250,
        }
    ];
    
    return (
        <div className={'doctor-list-table doctor-list-table-re'}>
            <div className={scrollClass}>
                <Table
                    columns={columns}
                    rowSelection={{
                        type: 'checkbox',
                        selectedRowKeys,
                        onChange: (selectedRowKeys) => {
                            dispatch(setSelectedRowKeys(selectedRowKeys));
                        }
                    }}
                    pagination={false}
                    dataSource={data}
                    loading={isFetching}
                    onChange={(pagination, filters, sorter) => handleChange(sorter)}
                    onRow={(record) => {
                        return {
                            onClick: (event) => {
                                onTableRowClick(record);
                            }
                        }
                    }}
                    tableLayout='fixed'
                    scroll={{y:'70vh', x: '1920px'}}
                />
            </div>
        </div>
    );
}

export default ReviewProcessingTable;