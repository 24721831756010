import { useMemo, useEffect, useState } from 'react';
import { Modal, Row, Col, Button, Divider } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';
import DiseaseResourceEmailTags from './disease-resource-email-tags';
import { getFilepathFromBackendMediaUrl } from '../../helpers/media-image-convert';

// Action imports
import { closeShareModal, createDiseaseResourceLog, diseaseResourceShareEmail } from '../../reducers/disease-resource-slice';
import '../../../static/css/components/disease-resource-share-modal.scss';

const DiseaseResourceShareModal = () => {

    const isShareModelOpen = useCare1AppSelector(store => store.diseaseResource.isShareModelOpen);
    const shareResourceId = useCare1AppSelector(store => store.diseaseResource.shareResourceId);
    const shareResourcePdfUrl = useCare1AppSelector(store => store.diseaseResource.shareResourcePdfUrl);
    const shareEmails = useCare1AppSelector(store => store.diseaseResource.shareEmails);
    const shareEmailSendingStatus = useCare1AppSelector(store => store.diseaseResource.shareEmailSendingStatus);

    const dispatch = useCare1AppDispatch();

    function handleModalClose(e: any): void {
        dispatch(closeShareModal());
    }

    const sendEmail = () => {
        dispatch(diseaseResourceShareEmail({
            resource_id: shareResourceId,
            emails: shareEmails,
        }));
    }

    const download = () => {
        try {

            const fileUrl = getFilepathFromBackendMediaUrl(shareResourcePdfUrl);

            const a = document.createElement('a');
            a.href = `${process.env.REACT_APP_BACKENDURL}/data/download/${fileUrl}`;
            a.download = fileUrl.split('/')[fileUrl.split('/').length - 1];

            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            dispatch(createDiseaseResourceLog({
                resource_id: shareResourceId,
                delivery_method: "download",
            }))
        }
        catch {

        }
    }

    return (
        <Modal
            title="Share"
            className='disease-resource-share-modal'
            open={isShareModelOpen}
            closable={true}
            footer={null}
            keyboard={true}
            onCancel={handleModalClose}
        >
            <Row>
                <Col span={24}>
                    <DiseaseResourceEmailTags />
                </Col>
            </Row>
            <Row className="button-rows">
                <Col span={24}>
                    <div>
                        {shareEmailSendingStatus === 'succeeded' && <Button
                            data-testid="disease-resource-share-modal-send-button-succeeded"
                            className="sent-succeeded"
                            block
                        >Send <CheckOutlined /></Button>}
                        {shareEmailSendingStatus !== 'succeeded' && <Button
                            data-testid="disease-resource-share-modal-send-button"
                            type='primary'
                            block
                            disabled={shareEmails.length === 0 || shareEmailSendingStatus === 'loading'}
                            onClick={sendEmail}
                        >Send</Button>}
                    </div>
                    <div>
                        <Divider plain>OR</Divider>
                    </div>
                    <div>
                        <Button
                            data-testid="disease-resource-share-modal-download-button"
                            type='primary'
                            block
                            onClick={download}
                        >Download</Button>
                    </div>
                </Col>
            </Row>
        </Modal>
    );
}

export default DiseaseResourceShareModal;
