import { useMemo, useEffect, useState } from 'react';
import { Flex, Row, Col } from 'antd';
import { useCare1AppDispatch, useCare1AppSelector } from '../../apps/care1-hooks';

// Action imports
import { openShareModal, IDiseaseResourceList, IDiseaseResourceListItem, 
    setShareResourceId, setShareEmails, setShareResourcePdfUrl } from '../../reducers/disease-resource-slice';


type ComponentProps = {
    listItems: IDiseaseResourceList;
}


const DiseaseResourceList = ({ listItems }: ComponentProps) => {

    const dispatch = useCare1AppDispatch();

    const handleValueClick = (resource_id: string, pdf_url: string) => {
        dispatch(setShareResourceId(resource_id));
        dispatch(setShareResourcePdfUrl(pdf_url.replaceAll("+", " ")));
        dispatch(setShareEmails([]));
        dispatch(openShareModal());
    }

    const renderValue = (values: IDiseaseResourceListItem[]) => {
        return (<>
            {values.map((v, index) => {
                return (<div 
                            key={index}
                            onClick={(e) => handleValueClick(v.resource_id, v.pdf_url)}
                        >{v.disease}</div>)
            })}
        </>);
    }

    return (
        <div className="disease-resource-list">
        <Flex 
            vertical
            wrap="wrap"
            gap="small">
            {Object.keys(listItems).map((letter, index) => {
                return (<div key={index} className="disease-block">
                    <div className="letter" data-testid={`disease-block-letter-${letter}`}>{letter}</div>
                    <div className="disease-name">{renderValue(listItems[letter])}</div>
                </div>)
            })}
        </Flex>
        </div>
    );
}

export default DiseaseResourceList;
