import { IOptionItem } from '../reducers/patient-exam-slice';
import { IBaseResponse, baseApi } from './base-api'

export interface IODEdit {
    showModal: boolean;
    showAddModal: boolean;
    showGroupPracticeModal: boolean;
    odGroupPracticeOperating: boolean;
    error: string;
    operating: boolean;
    addOperating: boolean;
    details: IODDetails;
}

export interface IGP {
    id?: number;
    first_name: string;
    last_name: string;
    province_id?: number;
    fax_number: string;
    province?: string;
}
export interface IODResponse {
    od:IODDetails;
}
export interface IOMDResponse {
    omd: IOMDDetails;
}

export interface IIGPResponse {
    igp: IIGPDetails;
}
export interface IDoctorResponse {
    success?: boolean;
    id?: string|number;
    error? : string;
    is_gp_duplicated?: boolean;
    fax_number?: string;
    od_group_practice_id?: number;
}
export interface IDoctorListResponse {
    doctors: [];
}

export interface IGPResponse {
    gps: IGP[];
}
// common fields for (OD, OMD, IGP)
export interface IDoctorDetails {
    id: number | string;
    first_name: string;
    last_name: string;
    username: string;
    email: string;
    telephone: string;
    fax: string;
    license_number: string;
    is_migrated: boolean;
    us_only: boolean;
    user_id: number | null;
}

// extend the Doctor Interface, only add fields that are bolong to OD only
export interface IODDetails extends IDoctorDetails {
    odgrouppractice: number | null;
    integrated_only: boolean;
    reimburse: boolean;
    orientating: boolean;
    rural: boolean;
    special_attention: boolean;
    provincial_id: string;
    province: number | null;
    default_omd: number | null;
    retina_only: boolean;
    send_gp_letters: boolean;
    hidden_from_dropdown: boolean;
    uses_referral_letter_pei: boolean;
    od_status: string;
    essentials_only: boolean;
    clinic_location: number | null;
    pei_toggle: boolean;
    technicians_to_receive_reviews: number[];
    send_reviews_to_od: boolean;
    internal_review_email: string;
    omd_internal_review_recipients: number[];
    has_glaucoma_program: boolean;
    gets_pdf_reviews: boolean;
}

// extend the Doctor Interface, only add fields that are bolong to OMD only
export interface IOMDDetails extends IDoctorDetails {
    home_province: number | null;
    omdnetwork: number | null;
    region: number[];
    agreed_to_review_provinces: number[];
    licensed_provinces: number[];
    is_reviewer: boolean;
    is_power_user: boolean;
    is_ret_specialist: boolean;
    is_generic: boolean;
    hidden_from_dropdown: boolean;
    receive_method: null | string;
    category: null | string;
    private_email: string;
    internal_review_email: string;
    receives_internal_reviews: boolean;
    other_omd_reviews_hidden: boolean;
}

export interface IOutboundOMDDetails {
    first_name: string;
    last_name: string;
    email: string;
    fax: string;
    receive_method: null | string;
    id?: number | null;
}

export const defaultOutboundOMDDetails:IOutboundOMDDetails  =  {
    first_name: '',
    last_name: '',
    email: '',
    fax: '',
    receive_method: null,
    id: null,
}

export interface IIGPDetails extends IDoctorDetails {
    omdnetwork: number | null;
}


export const defaultDoctorDetails: IDoctorDetails = {
    id: '',
    first_name: '',
    last_name: '',
    username: '',
    email: '',
    telephone: '',
    fax: '',
    license_number: '',
    is_migrated: false,
    us_only: false,
    user_id: null,
}
// default value of OD Edit Form
export const defaultODDetail: IODDetails =  {
    ...defaultDoctorDetails,
    odgrouppractice: null,
    integrated_only: false,
    reimburse: false,
    orientating: false,
    rural: false,
    special_attention: false,
    provincial_id: '',
    province: null,
    default_omd: null,
    retina_only: false,
    send_gp_letters: false,
    hidden_from_dropdown: false,
    uses_referral_letter_pei: false,
    od_status: '',
    essentials_only: false,
    clinic_location: null,
    pei_toggle: false,
    technicians_to_receive_reviews: [],
    send_reviews_to_od: false,
    internal_review_email: '',
    omd_internal_review_recipients: [],
    has_glaucoma_program: false,
    gets_pdf_reviews: false,
}
// default value of OMD Edit Form
export const defaultOMDDetail: IOMDDetails =  {
    ...defaultDoctorDetails,
    home_province: null,
    omdnetwork: null,
    region: [],
    agreed_to_review_provinces: [],
    licensed_provinces: [],
    is_reviewer: false,
    is_power_user: false,
    is_ret_specialist: false,
    is_generic: false,
    hidden_from_dropdown: false,
    receive_method: null,
    category: null,
    private_email: '',
    internal_review_email: '',
    receives_internal_reviews: false,
    other_omd_reviews_hidden: false,
}

// default value of OMD Edit Form
export const defaultIGPDetail: IIGPDetails =  {
    ...defaultDoctorDetails,
    omdnetwork: null,
}

export interface IGPLetter extends IDoctorDetails {
    doctorName: string;
}

export const doctorApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        // for get request, use builder.query <FirstArg, SecondArg>, FirstArg is the type of reponse
        // expected from the api call, second arg is the type of argument used in useGetOdDetailQuery
        // in this case, the response from getOdDetails is {od: IODDetail}
        getOdDetail: builder.query<IODResponse, number>({
            query: (id) => `od/${id}/`,
            // give a tag to a specific OD so that when this OD is updated by other API calls, its
            // tag(OD Details) is also updated (by refetching), note that _result and _error are not
            // used here, only id is used
            providesTags: (_result, _error, id) => [{type: 'OD', id}],
        }),
        // for Requests that will modify the server data (POST, PUT, DELETE), use builder.mutation
        // <FirstArg, SecondArg> same as above
        editOd: builder.mutation<IDoctorResponse, IODDetails>({
            query(requestData) {
                return {
                    url: 'od/edit/',
                    method: 'POST',
                    // the requestData in this case should be JSON, normally in createAsyncThung, we'd
                    // have to call JSON.stringify(requestData) manually, in RTK query, the object types
                    // of data is automatcially stringified
                    body: requestData,
                }
            },
            // Invalidate a specific OD by its id so that the OD tag is also updated, also invalidate
            // the Doctor List because one of the OD(doctors) is updated
            invalidatesTags: (result) => (
                result && result.success
                ? [{type: 'OD', id: result.id}, 'DoctorList', 'InternalReviewRecipients']
                : ['DoctorList']
            )
        }),
        addOd: builder.mutation<IDoctorResponse, IODDetails>({
            query(requestData) {
                return {
                    url: 'od/add/',
                    method: 'POST',
                    body: requestData,
                }
            },
            // Invalidate DoctorList because a new OD is added
            invalidatesTags: ['DoctorList', 'InternalReviewRecipients']
        }),
        getOmdDetail: builder.query<IOMDResponse, number>({
            query: (id) => `omd/${id}/`,
            providesTags: (_result, _error, id) => [{type: 'OMD', id}],
        }),
        editOmd: builder.mutation<IDoctorResponse, IOMDDetails>({
            query(requestData) {
                return {
                    url: 'omd/edit/',
                    method: 'POST',
                    body: requestData,
                }
            },
            // Invalidate a specific OMD by its id so that the OMD tag is also updated, also invalidate
            // the Doctor List because one of the OMD(doctors) is updated
            invalidatesTags: (result) => (
                result && result.success
                ? [{type: 'OMD', id: result.id}, 'DoctorList', 'InternalReviewRecipients']
                : ['DoctorList', 'InternalReviewRecipients']
            )
        }),
        addOmd: builder.mutation<IDoctorResponse, IOMDDetails>({
            query(requestData) {
                return {
                    url: 'omd/add/',
                    method: 'POST',
                    body: requestData,
                }
            },
            // Invalidate the Doctor List because one of the OMD(doctors) is updated
            invalidatesTags: ['DoctorList', 'InternalReviewRecipients']
        }),
        getIGPDetail: builder.query<IIGPResponse, number>({
            query: (id) => `igp/${id}/`,
            providesTags: (_result, _error, id) => [{type: 'IGP', id}],
        }),
        editIgp: builder.mutation<IDoctorResponse, IIGPDetails>({
            query(requestData) {
                return {
                    url: 'igp/edit/',
                    method: 'POST',
                    body: requestData,
                }
            },
            // Invalidate a specific IGP by its id so that the IGP tag is also updated, also invalidate
            // the Doctor List because one of the IGP(doctors) is updated
            invalidatesTags: (result) => (
                result && result.success
                ? [{type: 'IGP', id: result.id}, 'DoctorList']
                : ['DoctorList']
            )
        }),
        addIgp: builder.mutation<IDoctorResponse, IIGPDetails>({
            query(requestData) {
                return {
                    url: 'igp/add/',
                    method: 'POST',
                    body: requestData,
                }
            },
            // Invalidate the Doctor List because one of the IGP(doctors) is updated
            invalidatesTags: ['DoctorList']
        }),
        getDoctorList: builder.query<IDoctorListResponse, string>({
            query: (type) => `doctor/list/${type}`,
            // Give a tag to DoctorList so that other API actions can invalidate DoctorList
            providesTags: [{type: 'DoctorList', id: 'LIST'}]
        }),
        deleteDoctor: builder.mutation<IDoctorResponse, number|string|bigint>({
            query(doctorId) {
                const data = new FormData();
                data.append('doctor', doctorId.toString());
                return {
                    url: 'doctor/delete/',
                    method: 'POST',
                    body: data,
                }
            },
            // Invalidate DoctorList because an OD is deleted
            invalidatesTags: ['DoctorList']
        }),
        getGpsByProvince: builder.query<IGP[], string>({
            query: (provinceCode) => `gp/list/province_and_practice/?province=${provinceCode}`,
            // transform the response, before, the data returned by this hook is {gps: [...{gp}]}
            // after the transform, the data returned by this hooks is [...{gp}]
            transformResponse: (response: IGPResponse, meta, arg) => response.gps,
            providesTags: (result, error, arg) => 
                result
                ? [...result.map(({id}) => ({type: 'GP' as const, id})), 'GP']
                : ['GP']    
        }),
        getGpsByProvinceId: builder.query<IGP[], number>({
            query: (provinceId) => `gp/list/province_and_practice/?province_id=${provinceId}`,
            // transform the response, before, the data returned by this hook is {gps: [...{gp}]}
            // after the transform, the data returned by this hooks is [...{gp}]
            transformResponse: (response: IGPResponse, meta, arg) => response.gps,
            providesTags: (result, error, arg) => 
                result
                ? [...result.map(({id}) => ({type: 'GP' as const, id})), 'GP']
                : ['GP']    
        }),
        getAllGps: builder.query<IGP[], void>({
            query: () => `gp/list/`,
            // transform the response, before, the data returned by this hook is {gps: [...{gp}]}
            // after the transform, the data returned by this hooks is [...{gp}]
            transformResponse: (response: IGPResponse, meta, arg) => response.gps,
            providesTags: (result, error, arg) => 
                result
                ? [...result.map(({id}) => ({type: 'AllGP' as const, id})), 'AllGP']
                : ['AllGP']    
        }),
        addGp: builder.mutation<IDoctorResponse, Required<Omit<IGP, 'province_id' | 'id'>>>({
            query(newGP) {
                return {
                    url: 'gp/add/',
                    method: 'POST',
                    body: newGP
                }
            },
            invalidatesTags: ['GP']
        }),
        updateGp: builder.mutation<IDoctorResponse, Required<Omit<IGP, 'province_id'>>>({
            query(gp) {
                return {
                    url: 'gp/update/',
                    method: 'POST',
                    body: gp
                }
            },
            invalidatesTags: (result, error, arg) => [{type: 'GP', id: arg.id}]
        }),
        addRegion: builder.mutation<IDoctorResponse, {name: string} >({
            query(requestData) {
                return {
                    url: 'region/add/',
                    method: 'POST',
                    body: requestData,
                }
            }
        }),
        getOmdReceiveMethod: builder.query<IOptionItem[], void>({
            query: () => `omd/receive_methods/`,
            transformResponse: (response: IBaseResponse & {data: IOptionItem[]}, meta, arg) => 
                response.success ? response.data : [],
        }),
        getOmdCategory: builder.query<IOptionItem[], void>({
            query: () => `omd/category/`,
            transformResponse: (response: IBaseResponse & {data: IOptionItem[]}, meta, arg) => 
                response.success ? response.data : [],
        }),
        getDefaultOmd: builder.query<number | null, number> ({
            query: (id) => `od/default_omd/${id}`,
            transformResponse: (response: IBaseResponse & {data: number | null}, meta, arg) => response.data,
        }),
        getOmdOptions: builder.query<IOptionItem[], number> ({
            query: (id) => `exam/${id}/omd_options`,
            transformResponse: (response: IBaseResponse & {data:  IOptionItem[]}, meta, arg) => response.data,
            providesTags: (result = [], _error, _arg) => result.length ? [
                ...result.map(({key}) => ({type: 'OutboundOmd' as const, id: key})), {type: 'OutboundOmd', id: 'LIST'}
            ] : [{type: 'OutboundOmd', id: 'LIST'}],
        }),
        getOutboundOmdDetail: builder.query<IOutboundOMDDetails, number>({
            query: (id) => `omd/outbound/${id}`,
            transformResponse: (response: IBaseResponse & {data: IOutboundOMDDetails} , meta, arg) => response.data,
            providesTags: (_result, _error, id) => [{type: 'OutboundOmd', id}],
        }),
        editOutboundOmd: builder.mutation<IDoctorResponse, IOutboundOMDDetails>({
            query(requestData) {
                return {
                    url: 'omd/outbound/edit/',
                    method: 'POST',
                    body: requestData,
                }
            },
            // Invalidate a specific OMD by its id so that the OMD tag is also updated, also invalidate
            // the Doctor List because one of the OMD(doctors) is updated
            invalidatesTags: (result) => (
                result && result.success
                ? [{type: 'OutboundOmd', id: result.id}, 'OutboundOmd']
                : ['OutboundOmd']
            )
        }),
        addOutboundOmd: builder.mutation<IDoctorResponse, IOutboundOMDDetails>({
            query(requestData) {
                return {
                    url: 'omd/outbound/add/',
                    method: 'POST',
                    body: requestData,
                }
            },
            // Invalidate the Doctor List because one of the OMD(doctors) is updated
            invalidatesTags: ['OutboundOmd']
        }),
        getOmdInternalReviewRecipients: builder.query<IOptionItem[], number>({
            query: (odId) => `omd_internal_review_recipients/${odId}`,
            transformResponse: (response: IBaseResponse & {data: IOptionItem[]}, meta, arg) => response.data,
            providesTags: ['InternalReviewRecipients'],
        }),
        getTeleConsultOptions: builder.query<IOptionItem[], void>({
            query: () => `omd/tele_consult_options/`,
            transformResponse: (response: IBaseResponse & {data: IOptionItem[]}, meta, arg) => response.data,
        }),
    }),
});

// all the RKT query hooks are automaticallly generated, press Ctrl + Space inside the const {} block
// below to get the RTK query hooks auto filled by VS Code, these hooks will be used in other components
// that need the data
export const {useGetOdDetailQuery, useGetDoctorListQuery, useEditOdMutation, useAddOdMutation,
    useDeleteDoctorMutation, useGetOmdDetailQuery, useEditOmdMutation,
    useAddOmdMutation, useAddIgpMutation, useEditIgpMutation, useGetIGPDetailQuery,
    useGetGpsByProvinceQuery, useGetAllGpsQuery, useAddGpMutation, useUpdateGpMutation,
    useAddRegionMutation, usePrefetch, useGetOmdReceiveMethodQuery, useGetOmdCategoryQuery,
    useLazyGetDefaultOmdQuery, useGetOmdOptionsQuery, useEditOutboundOmdMutation, useAddOutboundOmdMutation,
    useGetOutboundOmdDetailQuery, useGetDefaultOmdQuery, useGetOmdInternalReviewRecipientsQuery,
    useGetTeleConsultOptionsQuery, useGetGpsByProvinceIdQuery
} = doctorApi;