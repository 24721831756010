import { Row, Col, Card } from 'antd';
import PatientExamChiefComplaint from '../../../components/retina/patient-exam-chief-complaint';
import ReferralLetterExamMetrics from '../../../components/integrated/metrics/referral-letter-exam-metrics';
import PatientExamOMDHistory from '../../../components/integrated/patient-exam-omd-history';
import PatientExamAllergies from '../../../components/integrated/patient-exam-allergies';

import '../../../../static/css/components/admin-gpt-ai-snapshot-band.scss';

type ComponentProps = {
    impressions: string[],
    odAssessment: string,
    odQuestion: string,
};

const OmdrGptAISnapshotBand = ({ impressions, odAssessment, odQuestion }: ComponentProps) => {

    return (
        <div className="omdr-gpt-ai-snapshot-band">
            <Row gutter={8} className='complaints-metrics-history-row'>
                <Col span={4}>
                    <PatientExamChiefComplaint disabled={true} />
                </Col>
                <Col span={12} className='exam-metrics'>
                    <ReferralLetterExamMetrics disabled={true} />
                </Col>
                <Col span={34}>
                    <PatientExamOMDHistory disabled={true} />
                </Col>
                <Col span={4}>
                    <PatientExamAllergies disabled={true} />
                </Col>
            </Row>

            <Row className="gpt-ai-snapshot-band">
                <Col span={8}>
                    <Card className={''} bordered={false}>
                        <div className="heading">
                            Impression
                        </div>
                        <div className="component-input">
                            {
                                impressions && impressions.map(
                                    (string, index) => <div className="impression-multi-value">{string}</div>
                                )
                            }
                            {
                                impressions.length === 0 && 
                                    <div>No impression detected.</div>
                            }
                        </div>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card className={''} bordered={false}>
                        <div className="heading">
                            Plan
                        </div>
                        { odAssessment !== '' && 
                            <div className="component-input">
                                {odAssessment}
                            </div>
                        }
                        { odAssessment === '' && 
                            <div className="component-input">
                                No Plan detected.
                            </div>
                        }
                    </Card>
                </Col>
                <Col span={8}>
                    <Card className={'ai-snapshot-last'} bordered={false}>
                        <div className="heading gpt-ai-snapshot-band-heading">
                            OD Question
                        </div>
                        { odQuestion !== '' && 
                            <div className="component-input-small od-question-detected">
                                {odQuestion}
                            </div>
                        }
                        { odQuestion === '' && 
                            <div className="component-input-small">
                                No OD question detected.
                            </div>
                        }
                    </Card>
                </Col>
            </Row>
        </div>);
}

export default OmdrGptAISnapshotBand;