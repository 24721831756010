import { baseApi, IBaseResponse } from './base-api'
import { IPatientResponse } from './patient-api';

export interface IUploadReconcilerPatientMatchingTableItem {
    key: number,
    id: number,
    patient_name: string,
    dob: string,
    insurance: string | null,
    exam_id: number | null,
}

export interface IUploadReconcilerPatientMatchingRequest {
    dob: string,
    first_name: string,
    last_name: string,
    insurance: string,
    od_group_practice_name: string,
}

export interface IUploadReconcilerAddPatientRequest {
    od_id: number;
    first_name: string;
    last_name: string;
    province: number | null;
    phn: string;
    dob: string;
    primary_phone: string | null;
    insurance_type: string;
    gp_id: number | null;
    gp_fax_number: string | null;
}

export interface IUploadReconcilerMatchPatientsRequestItem {
    id: number;
    op: string;
}

export interface IUploadReconcilerMatchPatientsRequest {
    items: IUploadReconcilerMatchPatientsRequestItem[];
}

export const uploadReconcilerApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getUploadReconcilerPatientMatchingList: builder.query<IUploadReconcilerPatientMatchingTableItem[], IUploadReconcilerPatientMatchingRequest>({
            query: ({dob, first_name, last_name, insurance, od_group_practice_name}) => {
                return {
                    url: `upload_reconciler/patient_matching_list/`,
                    params: {
                        dob,
                        first_name,
                        last_name,
                        insurance,
                        od_group_practice_name,
                    },
                }
            } ,
            transformResponse: (response: IBaseResponse & {data: IUploadReconcilerPatientMatchingTableItem[]}, meta, arg) => response.data,
        }),
        addReconcilerPatientPatient: builder.mutation<IPatientResponse, IUploadReconcilerAddPatientRequest>({
            query(requestData) {
                return {
                    url: 'upload_reconciler/patient_add/',
                    method: 'POST',
                    body: requestData,
                }
            },
        }),
        matchReconcilerPatientPatients: builder.mutation<IPatientResponse, IUploadReconcilerMatchPatientsRequest>({
            query(requestData) {
                return {
                    url: 'upload_reconciler/patient_match/',
                    method: 'POST',
                    body: requestData,
                }
            },
        }),
        getMatchingOdId: builder.query<IBaseResponse & {id?: number}, {name: string, location: string} >({
            query: ({name, location}) => {
                return {
                    url: 'upload_reconciler/matching_od',
                    params: {
                        name,
                        location
                    }
                }
            }
        })
    }),
});

// all the RKT query hooks are automaticallly generated, press Ctrl + Space inside the const {} block
// below to get the RTK query hooks auto filled by VS Code, these hooks will be used in other components
// that need the data
export const {
    useLazyGetUploadReconcilerPatientMatchingListQuery, useAddReconcilerPatientPatientMutation, 
    useMatchReconcilerPatientPatientsMutation, useLazyGetMatchingOdIdQuery
} = uploadReconcilerApi;
