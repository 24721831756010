import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Modal } from 'antd';
import { getCsrfToken } from '../helpers/utilities';
import { apiRequest } from "../services/api-request";
import { logout } from "./user-slice";
import { IExamsWithLeftVf, IExamsWithRightVf, IExamsWithRightOctRnfl, IExamsWithLeftOctRnfl, IGraphIop } from '../reducers/patient-exam-slice';
import { ITxalgo3 } from '../reducers/txalgo3-slice';


export interface IDeepMdBandScreenshotState {
    deepMdBandScreenshotIsFetching: boolean;
    susp_features: string[],
    reas_features: string[],
    exams_with_left_vf: IExamsWithLeftVf[],
    exams_with_right_vf: IExamsWithRightVf[],
    exams_with_left_oct_rnfl: IExamsWithLeftOctRnfl[],
    exams_with_right_oct_rnfl: IExamsWithRightOctRnfl[],
    graph_iop: IGraphIop | null;
    txalgo3: ITxalgo3 | null,
    fu_periods: string[][],
    rr_glc: boolean,
    rr_glc_suspect: boolean,
}

const initialState: IDeepMdBandScreenshotState = {
    deepMdBandScreenshotIsFetching: false,
    susp_features: [],
    reas_features: [],
    exams_with_left_vf: [],
    exams_with_right_vf: [],
    exams_with_left_oct_rnfl: [],
    exams_with_right_oct_rnfl: [],
    graph_iop: null,
    txalgo3: null,
    fu_periods: [],
    rr_glc: false,
    rr_glc_suspect: false,
};

export const getMdBandData = createAsyncThunk(
    'deepMdBandScreenshot/getMdBandData',
    async (examId: string, { dispatch, getState, rejectWithValue }) => {
        const URL = `${process.env.REACT_APP_BACKENDURL}/data/deep_md_band_screenshot/get_md_band_data/${examId}/`;
        try {
            const response = await apiRequest.get(URL, '');
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

const deepMdBandScreenshotSlice = createSlice({
    name: 'deepMdBandScreenshot',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getMdBandData.pending, (state, action) => {
            state = {...initialState};
            state.deepMdBandScreenshotIsFetching = true;
        });
        builder.addCase(getMdBandData.fulfilled, (state, action) => {
            state.deepMdBandScreenshotIsFetching = false;
            state.susp_features = action.payload.data.susp_features;
            state.reas_features = action.payload.data.reas_features;
            state.exams_with_left_vf = action.payload.data.exams_with_left_vf;
            state.exams_with_right_vf = action.payload.data.exams_with_right_vf;
            state.exams_with_left_oct_rnfl = action.payload.data.exams_with_left_oct_rnfl;
            state.exams_with_right_oct_rnfl = action.payload.data.exams_with_right_oct_rnfl;
            state.graph_iop = action.payload.data.graph_iop;
            state.txalgo3 = action.payload.data.txalgo3;
            state.fu_periods = action.payload.data.fu_periods;
            state.rr_glc = action.payload.data.rr_glc;
            state.rr_glc_suspect = action.payload.data.rr_glc_suspect;
        });
        builder.addCase(getMdBandData.rejected, (state, action) => {
            state = {...initialState};
            state.deepMdBandScreenshotIsFetching = false;
        });
    }
});

export default deepMdBandScreenshotSlice.reducer;

