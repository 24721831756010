import { Tabs } from 'antd';
import Gpt from './gpt';
import GptVision from '../gpt-vision/gpt-vision';
import GptAISnapshot from './gpt-ai-snapshot';
import GptPatientDemographics from './gpt-patient-demographics';
import GptImageIdentifier from '../image-identifier/gpt-image-identifier';
import { useState } from 'react';

const GptTabs = () => {
    const [activeKey, setActiveKey] = useState('item-gpt-chat');

    return (
        <Tabs
            defaultActiveKey={'item-gpt-chat'}
            activeKey={activeKey}
            onTabClick={(key) => setActiveKey(key)}
            items={[
                {
                    label: 'GPT Chat',
                    key: 'item-gpt-chat',
                    children: <Gpt />,
                },
                {
                    label: 'GPT Vision',
                    key: 'item-gpt-vision',
                    children: <GptVision />,
                },
                {
                    label: 'AI Snapshot',
                    key: 'item-gpt-snapshot',
                    children: <GptAISnapshot />,
                },
                {
                    label: 'Demographics Testing',
                    key: 'item-gpt-demographics',
                    children: <GptPatientDemographics />,
                },
                {
                    label: 'Image Identifier',
                    key: 'item-gpt-image',
                    children: <GptImageIdentifier />,
                },
            ]}
        />
    );
};

export default GptTabs;
